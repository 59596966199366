<template>
	<div>
		<div md="12">
			<b-alert show variant="primary">
				<div class="alert-body">
					<span>{{$t("Exclusively for reservations with contract nights")}}</span>
				</div>
			</b-alert>
		</div>
		<ReservationForm
			class="mt-1"
			ref="RequestReservationForm"
			:bookingEdit="booking"
			:isSearchingRooms="isSearchingRooms"
			:isSavingRequestReservation="isSavingRequestReservation"
			:isEditing="true"
			:isRequest="true"
			@send-request-booking="handlerSendRequest"   
			@set-rooms="setRooms"
		/>

		<br>

		<b-overlay :show="isSavingRequestReservation" >
			<Rooms :rooms="rooms" :isSearchingRooms="isSearchingRooms" @set-book="bookRoom" v-if="showRooms"/>
		</b-overlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { heightFade } from "@core/directives/animations";
import { toJson } from "@/helpers/helpers";
import { market } from "@/modules/market/mixins/market";
import { utils } from "@/modules/owners/mixins/utils"

import { currentDate } from "@/helpers/market";
import Rooms from '@/modules/owners/components/reservations/Rooms'
import ReservationForm from '@/modules/owners/components/reservations/ReservationForm'
import { createPayloadRequestBooking, createPayloadBooking, formatRooms } from "@/helpers/bookingHelper"

export default {
	mixins: [market, utils],	
	components: {
		ReservationForm,
		Rooms
	},
	directives: {
		heightFade,
	},
	created() {
		if (this.booking.adultsDetail.length === 1) {
			const { name, lastname, phone, email } = this.user;
			this.booking.adultsDetail[0].name = name.trim();
			this.booking.adultsDetail[0].lastname = lastname.trim();
			this.booking.adultsDetail[0].phone = phone ? phone.trim() : "";
			this.booking.adultsDetail[0].email = email ? email.trim() : "";
			this.booking.adultsDetail[0].ismain = true;
		}
	},
	data() {
		return {
			booking: {
				checkIn: "",
				checkInCheckOut: "", //rango de fechas
				checkOut: "",
				comments: "",
				isAGuest: false,
				nights: 0,
				numberAdults: 1,
				numberChildren: 0,
				adultsDetail: [{
					key: "ec265294-fe41-11ec-b939-0242ac120002",
					name: "",
					lastname: "",
					phone: "",
					email: "",
					ismain: true,
					adult: true,
					edad: "",
				}],
				dataChildrens: []
			},
			max: 255,
			isSavingRequestReservation: false,
			isSearchingRooms: false,
			showRooms: false,
			bookingToSend: null,
			fechaActual: currentDate(),
			rooms: []
		}
	},
	computed: {
		...mapState("auth", ["user", "myContracts"]),
	},
	methods: {
		...mapActions('owners', ['sendBooking', 'getRooms', 'fetchBookings', 'validateBooking', 'saveBookingOwner','pushActionsService']),
		...mapMutations('owners', ['setBookings']),
		setRooms(rooms){
			this.rooms = rooms		
		},
		async handlerSendRequest(booking) {
			this.isSearchingRooms = true;
			this.showRooms = false

			await this.pushData('onClick', 'requestReservation', 'requestReservation', this.$route.name ) // push a back del registro

			//validar primero disponibillidad de noches
			const payloadRequest = toJson(createPayloadRequestBooking(booking, this.myContracts?.contractSelected, this.owner))
			const rawRooms = await this.getRooms(payloadRequest)

			if( rawRooms.length === 0 ){
				this.noNightsAvailable = true
				this.isSearchingRooms = false		
				// push a back del registro
				await this.pushData('onClick', 'searchRoomsFailed', 'searchRoomsFailed', this.$route.name, 'It looks like you have no nights available')
				return this.$swal.fire({icon: "error", title: `${this.$t("Not enough nights")}`, text: `${this.$t("The reservation does not have enough nights")}`,});
			}

			if(rawRooms.length > 0 ) this.rooms = formatRooms(rawRooms)

			//validar disponibilidad para proceder rsv
			const { isInvalidNights, needAuthorization, existAReservation, hasReservationsNearDates, invalidResponse } = await this.validateRsv(booking)
			// console.log({ isInvalidNights, needAuthorization, existAReservation, hasReservationsNearDates })

			if (invalidResponse || isInvalidNights || existAReservation || needAuthorization) {
				let icon = "error"
				this.isSearchingRooms = false

				const title =
				invalidResponse ? `${this.$t("something failed")}` :
				isInvalidNights ? `${this.$t("Not enough nights")}` :
				existAReservation ? `${this.$t("A reservation found")}` :
				needAuthorization ? this.$t("This reservation does not comply with the contract criteria") : ''

				const text =
				invalidResponse ? `${this.$t("try again")}` :
				isInvalidNights ? `${this.$t("The reservation does not have enough nights")}` :
				existAReservation ? `${this.$t("There is already a reservation within these days")}` :
				needAuthorization ? this.$t("This reservation does not comply with the contract criteria...") : ''

				//si el contracto es CLASSIC y la respuesta de getOwnerBookingBlack trae un additional en true
				if (existAReservation) icon = "warning"
				// push a back del registro
				await this.pushData('onClick', 'searchRoomsFailed', 'searchRoomsFailed', this.$route.name, title) 

				return this.$swal.fire({ icon, title, text })
			}

			// Si hay reservaciones Cerca
			if( hasReservationsNearDates ){
				this.isSearchingRooms = false
				const manyBooks = hasReservationsNearDates?.data?.length > 1
				await this.nearDatesModal({
					icon: 'AlertCircleIcon',
					title: manyBooks ? this.$t("Several nearby reserves have been found") : this.$t("A nearby reserve found"),
					message: `${ manyBooks ? this.$t("There are reservations") : this.$t("There is a reserve")} ${ this.$t("near the selected days") } ${ this.$t("Choose other dates with a difference of")}   ${hasReservationsNearDates?.lapse} ${ this.$t("days") }`,
					data: hasReservationsNearDates?.data
				})

				// push a back del registro
				await this.pushData('onClick', 'searchRoomsFailed', 'searchRoomsFailed', this.$route.name, 'A nearby reserve found near the selected days') 

				return false
			}

			this.showRooms = true
			this.bookingToSend = booking

			this.isSearchingRooms = false
		},
		async nearDatesModal(info){
			const {icon, title, message, data} = info
			const h = this.$createElement

			// const titleVNode = h('label', { domProps: { innerHTML: title } })

			const datafields = [
				{key: 'code', label: this.$t('booking ID') },
				{key: 'datein', label:  this.$t('Check in') },
				{key: 'dateout', label: this.$t('Check out') }
			]

			const messageVNode = h('div', { class: ['foobar'] }, [
				// h('feather-icon', { props: {icon} }),
				h('p', { class: ['text-center mb-1'] }, [
					`${message}`,
					h('br'),
					h('strong', [` ${this.$t('Your dates') }: ${this.booking.checkIn} ➡️ ${this.booking.checkOut}`]),
				]),
				h('b-table', {
					props: {
						items: data,
						fields: datafields,
						small: true,
						responsive: true,
					}
				})
			])

			const modal = await this.$bvModal.msgBoxOk([messageVNode],{
				title,
				size: 'sm',
				centered: true,
				hideHeaderClose: false,
			})

			return modal
		},

		async bookRoom( room ){
			this.isSearchingRooms = true
			this.isSavingRequestReservation = true

			const { nightsContract,  idconfigurationRoom } = room
			const payload = createPayloadBooking(this.bookingToSend, this.myContracts?.contractSelected)

			payload.nightsContract = nightsContract
			payload.nightsXtra = false
			payload.idconfigurationRoom = idconfigurationRoom

			const { idBooking, bookingWasCreated, message } = await this.sendRequestBooking(payload, room)

			this.isSearchingRooms = false
			this.isSavingRequestReservation = false

			if(idBooking && bookingWasCreated){
				await this.requestBookingWasSuccessfully()
				await this.pushData('onSubmit', 'requestReservationSuccessfull', 'requestReservationSuccessfull', this.$route.name ) // push a back del registro
			} else {
				this.requestBookingError(message)
			}
		},
		async sendRequestBooking(payload, room) {
			const { nightsContract,  idconfigurationRoom } = room
			payload.nightsContract = nightsContract
			payload.nightsXtra = false
			payload.idconfigurationRoom = idconfigurationRoom
			let response = { idBooking: null, bookingWasCreated: false, message: '' }

			const { status:statusRequestBooking, data } = await this.sendBooking(toJson(payload))

			if (statusRequestBooking) {

				response.idBooking = data //en data viene un id que se vuelve el idBooking

				const { status:statusRequestOwner, message:msg } = await this.saveBookingOwner({ idBooking: data, nightsContract, nightsXtra:false })
				response.bookingWasCreated = statusRequestOwner //si todo fue exitoso, este se volverá true
				response.message = msg || ''

			}
			return response
		},
		async requestBookingWasSuccessfully(){
			const { housingid, id } = this.myContracts.contractSelected // hFousingid del contrado seleccionado

			const title = this.$t("Your reservation has been confirmed")
			const text = this.$t("Your reservation has been confirmed the E-Concierges")
			this.$swal.fire({ icon: "success", title, text }) //muestro alerta de exito
			//pedir y mutar el listado de reservas
			const bookings = await this.fetchBookings({housingid, idContract: id})
			this.setBookings(bookings)
			this.$refs.RequestReservationForm.resetForm()
			this.$emit("go-to-tab", 0) //mandar al tab listado de reservas
		},
		requestBookingError( message = '' ){
			const title = this.$t("This reservation does not comply with the contract criteria")
			const text = message == '' ? this.$t("This reservation does not comply with the contract criteria...") : message
			this.$swal.fire({ icon: "error", title, text }) //muestro alerta de error
		},
		async validateRsv(booking) {
			const { checkIn, checkOut } = booking;
			const { id, membershipcode } = this.myContracts.contractSelected; //id del contrato
			const payload = {
				idOwner: this.user.idOwner,
				idBooking: 0,
				idContract: id,
				dateIn: checkIn,
				dateOut: checkOut,
				isEdit: false,
			}
			const response = await this.validateBooking(payload); //petición al endpoint getOwnerBookingBlack

			if (response){
				const { status, cutoff, additional, info, booksNear  } = response
				return {
					isInvalidNights: false,
					needAuthorization: status || cutoff,
					existAReservation: membershipcode === 'CLASSIC' && additional,
					reasonAuthorization : info ? `${info?.name || '' }, días: ${info?.total || '' } `: '',
					hasReservationsNearDates: booksNear,
					invalidResponse: false
				}
			} else return {invalidResponse: true}

		},
		async resetForm() {
			Object.assign(this.$data, this.$options.data()); //limpio data o reset
			this.$refs.formRequestReservation.reset();
			await this.pushData('onClick', 'resetForm', 'resetForm', this.$route.name ) // push a back del registro
		},   
	},

};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>