<template>
  <div style="padding: 1rem;">
    <b-card >
      <b-card-body>
        <b-tabs active v-model="tabIndex">
          <b-tab @click="clickTabRsv($t('Reservations'))">
            <template #title>
                <feather-icon icon="CalendarIcon" size="18" />
              <span>{{ $t('Reservations')}} </span>
            </template>

    
			<b-overlay :show="isLoadingBookings">
				<Filters
					:perPage="perPage"
					:perPageOptions="perPageOptions"
					:searchQuery="searchQuery"
					@change-per-page="changePerPage"
				/>
				<Table
					:bookings="reservations"
					:tableColumns="tableColumns"
					:sortBy="sortBy"
					:isSortDirDesc="isSortDirDesc"
					:requiresTermsAndConditions="requiresTermsAndConditions"
					@get-bookings="getOnlyBookings"
				/>
				<PaginationTable
					:currentPage="currentPage"
					:totalRows="bookings.length"
					:perPage="perPage"
					@change-page="changePage"
					v-show="bookings.length"
				/>
			</b-overlay>

          </b-tab>
          <b-tab v-if="canMakeReservation" @click="clickTabRsv($t('Request a reservation'))">
            <template #title>
              <feather-icon icon="BriefcaseIcon" size="18" />
              <span>{{ $t('Request a reservation')}}  </span>
            </template>
            <RequestReservations @go-to-tab="goToTab" @set-year="setYear"/>
          </b-tab>

        </b-tabs>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapState, mapActions, mapMutations } from 'vuex'
import vSelect from 'vue-select'
import * as moment from "moment"
import { utils } from '@/modules/owners/mixins/utils'
import Filters from '@/modules/owners/components/reservations/Filters'
import RequestReservations from '@/modules/owners/components/reservations/RequestReservations'
import Table from '@/modules/owners/components/reservations/Table'
import PaginationTable from '@/modules/owners/components/reservations/PaginationTable'
import SkeletonMyReservations from '@/modules/owners/components/reservations/skeletons/SkeletonMyReservations'
import { formatDate } from '@/helpers/helpers'
import { market } from '@/modules/market/mixins/market'

export default {
	mixins:[utils, market],

	components: {
		vSelect,
		Filters,
		RequestReservations,
		Table,
		PaginationTable,
		SkeletonMyReservations
	},
	async created(){
		this.setBookings([])
		await this.getInitialData()
		await this.getBenefitsByContractYear()
	},
	data() {
		return {
			isLoadingBookings: true,
			currentYear: new Date().getFullYear(),
			perPage: 10,
			currentPage: 1,
			perPageOptions: [5, 10, 15, 20, 25, 50, 100],
			searchQuery: '',
			sortBy: '',
			isSortDirDesc: false,
			requiresTermsAndConditions: false,		
			tabIndex: 0,
		}
	},
	computed: {
		...mapState('owners', ['bookings','cancellationReasons']),
		...mapState('auth', ['user','myContracts']),
		...mapState('market',['customerCurrency']),
		tableColumns(){
			return[
				{ key: 'invoice', label: this.$t('Order Number'), },
				{ key: 'createdate', label: this.$t('Booking date'), sortable: true, formatter: value => {
					return this.formDate(value)
				}},
				{ key: 'fullname', label: this.$t('reservation holder'), },
				{ key: 'nights', label: this.$t('Nights'), },
				{ key: 'datein', label: this.$t('Check in'), sortable: true, formatter: value => {
					return this.formDate(value)
				}},
				{ key: 'dateout', label: this.$t('Check out'), sortable: true, formatter: value => {
					return this.formDate(value)
				}},
				{ key: 'adults', label: this.$t('Pax'), },
				// { key: 'isguest', label:'Is a Guest'},
				{ key: 'statusname', label: this.$t('Status'), },
				{ key: 'occupanttype', label: this.$t('Type of occupant'), class: 'text-capitalize', formatter: value => {
					return value == 1 ? this.$t('owner') : value == 2 ? this.$t('Invited') : '-'
				}},
				// { key: 'occupantname', label: this.$t('Type of occupant'), },

			]
		},
		reservations(){
			const items = this.bookings
			return items.slice( (this.currentPage - 1) * this.perPage, this.currentPage * this.perPage )
		},
		canMakeReservation(){
			let existeCategoryRoom = false
			if(this.myContracts){
				const { categoryroomId  } = this.myContracts.contractSelected
				existeCategoryRoom = categoryroomId && categoryroomId != null  //si viene categoryroomId y es diferente a nulo
			}
			return this.user.canBook && existeCategoryRoom // si user tiene la bandera canBook en true y existeCategoryRoom es true
		}
	},
	methods:{
		...mapActions('owners', ['fetchBookings','fetchBenefitsByYearContract','pushActionsService', 'cancelOwnerBooking','fetchCancellationReasons']),
		...mapMutations('owners',['setBookings']),
		...mapMutations('market', ['setCurrencyCustomer']),
		...mapMutations('auth', ['setBenefitsContractSelected']),

		changePage(page){
			this.currentPage = page
		},
		changePerPage(perPage){
			this.perPage = perPage
		},
		async getInitialData(){
			if(this.myContracts){
				const { housingid, id } = this.myContracts.contractSelected // hFousingid del contrado seleccionado
				this.isLoadingBookings = true
			
				await this.fetchInitialDataMarket()
				const termsConditions = await this.getParameters(8)
				this.requiresTermsAndConditions = Boolean(termsConditions?.value)
				if (!this.cancellationReasons || this.cancellationReasons?.length == 0) await this.fetchCancellationReasons(70)
				const bookings = await this.fetchBookings({housingid, idContract: id})
			
				this.setBookings(bookings)
				this.isLoadingTopCards = false
				this.isLoadingBookings = false
				if ( !this.customerCurrency ) {
					this.setCurrencyCustomer( {currencyid: 1, code: "USD", value: "1.00" })
				}
			}
		},
		async getOnlyBookings(){
			this.isLoadingBookings = true
			this.setBookings([])
			const { housingid, id } = this.myContracts.contractSelected // hFousingid del contrado seleccionado
			const bookings = await this.fetchBookings({housingid, idContract: id})
			this.setBookings(bookings)
			this.isLoadingBookings = false
		},
		sortBookingsByCheckinDateAsc( bookings ){
			bookings.sort((a, b) => b.datein.localeCompare(a.datein));
			return bookings
		},
		async getBenefitsByContractYear(){
			if(this.myContracts){
				const { id, dateStart } = this.myContracts.contractSelected // housingid del contrado seleccionado
				const yearInitContract =  moment(dateStart).year()
				const year = yearInitContract > this.currentYear ? yearInitContract: this.currentYear
				const rawBenefits = await this.fetchBenefitsByYearContract({idContract: id, year, typeBenefit: "Noches", benefit: "NOCHESXC"})
				const benefits = rawBenefits.length ? this.formatBenefits(rawBenefits) : []
				this.setBenefitsContractSelected(benefits)
			}
		},
		formatBenefits( rawBenefits){
			let benefits = []
			rawBenefits.forEach( beneficio => {
				const { benefit } = beneficio
				//solo necesito los que vienen en false
				if( !benefit.includebooking ){
					benefit.isSelected = false // por defecto no está marcado
					benefits.push( benefit )
				}
			})
			// marcar el primer beneficio  que no sea code = TA como selected
			const benefitMarked = benefits.find( benefit => benefit.code !== "TA" )
			if(benefitMarked){
				benefitMarked.isSelected = true
			}
			return benefits
		},
		goToTab(index){
			this.tabIndex = index
		},
		setYear(year){
			this.currentYear = year
		},
		async clickTabRsv(infoTab){
			await this.pushData('onClick', 'clickTabOption', 'clickTabOption', this.$route.name, infoTab) // push a back del registr
		},
	
		formDate(date){
			return formatDate({date, language: this.user?.languageName})
		}
	},
	watch: {
		myContracts: {
			deep: true,
			async handler() {
				await this.getInitialData()
			},
		},
		// si el año del range date cambia (dateOut), el current year, pedir los benefits
		currentYear: {
			async handler() {
				await this.getBenefitsByContractYear()
			},
			// console.log('he cambiado')
		}
	},
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
